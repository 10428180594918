import axios from 'axios'
import router from '../router'
import { Toast } from 'vant'

const http = axios.create({
  baseURL: 'https://user.deviceweixiu.com',
  // baseURL: 'https://device.xuanwumobile.com/',
  // baseURL: 'https://user.deviceweixiu.com/',
  timeout: 5000,
  headers: { 
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
  },
})

// 添加请求拦截器
http.interceptors.request.use(
  function (config) {
    // 在发送请求之前做些什么

    if (localStorage.getItem('wmdAllUser')) {
      // config.data.user_id = 4
      config.data.user_id =
        JSON.parse(localStorage.getItem('wmdAllUser')).user_id || 0
    } else {
      config.data.user_id = 0
    }
    // console.warn(
    //   '入参:' + config.baseURL + config.url + JSON.stringify(config.data)
    // )
    // if (
    //   config.url == 'api/Home/goodsJoinDetail' ||
    //   config.url == 'api/Home/secondAndThirdCate' ||
    //   config.url == 'api/Home/getGoodsCateInfo' ||
    //   config.url == 'api/Home/selectGoodsCate' ||
    //   config.url == 'api/Home/goodsCate'
    // ) {
      console.warn(
        '入参:' + config.baseURL + config.url + JSON.stringify(config.data)
      )
    // }
    return config
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error)
  }
)

// 添加响应拦截器
http.interceptors.response.use(
  function (response) {
    console.warn('返回参:' + JSON.stringify(response))
    // if (
    //   response.config.url == 'api/Home/goodsJoinDetail' ||
    //   response.config.url == 'api/Home/secondAndThirdCate' ||
    //   response.config.url == 'api/Home/getGoodsCateInfo' ||
    //   response.config.url == 'api/Home/selectGoodsCate' ||
    //   response.config.url == 'api/Home/goodsCate'
    //   response.config.url == 'api/Ma/kefuOnline'
    // ) {
    //   console.warn('返回参:' + JSON.stringify(response.data))
    // }
    // console.log(response.data.url)
    // 2xx 范围内的状态码都会触发该函数。
    // 对响应数据做点什么
    // 88未登录
    if (
      response.data.status != 200 &&
      response.config.url != 'api/Base/uploadFile'
    ) {
      Toast(response.data.msg)
      // Message({
      //   message: response.data.msg,
      //   type: 'error',
      // })
      // if (response.data.status == 88) {
      //   router.push({ name: 'Login' })
      //   // this.$router.push({
      //   //   name: 'Login',
      //   //   params: {},
      //   // })
      //   // vue.toLogin()
      // }
      // return
    } 
    return response.data
  },
  function (error) {
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    console.warn(JSON.stringify(error))
    return Promise.reject(error)
  }
)

export default http
