// 引入ali-oss
let OSS = require('ali-oss')
let client = new OSS({
    region: 'oss-cn-hangzhou', // bucket所在的区域， 默认oss-cn-hangzhou
    secure: true, // secure: 配合region使用，如果指定了secure为true，则使用HTTPS访问
    accessKeyId: 'LTAI5tHSyjdtvxmv6rEkxHzH', // 通过阿里云控制台创建的AccessKey
    accessKeySecret: 'zc0NnP7kvc2UDZTwWue7NXFn7aDpc1', // 通过阿里云控制台创建的AccessSecret
    bucket: 'xwnj', // 通过控制台或PutBucket创建的bucket
})
export const put = async (ObjName, fileUrl) => {
  try {
    let result = await client.put(`${ObjName}`, fileUrl)
    // ObjName为文件名字,可以只写名字，就直接储存在 bucket 的根路径，如需放在文件夹下面直接在文件名前面加上文件夹名称
    return result
  } catch (e) {
    console.log(e)
    // alert('上传失败',JSON.stringify(error));
  }
}
